import * as React from "react"
import { graphql, Link } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { Hero } from "../components/hero"
import SideCTA from "../components/sideCTA"
import Card from "../components/card"

export const query = graphql`
  query HomePageQuery {
    file(name: { eq: "home-page-banner-kids" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    contentfulCardCollection(cardCollectionTitle: { eq: "Home" }) {
      cardCollection {
        references {
          id
          heading
          image {
            gatsbyImageData(aspectRatio: 1.5)
          }
          imageAltText
          cardContent {
            raw
          }
          buttonText
          destination
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="font-serif text-gray-500 text-lg">{children}</p>
      ),
      [BLOCKS.LIST_ITEM]: node => <li>{node.content[0].content[0].value}</li>,
    },
  }

  return (
    <Layout>
      <SEOComponent
        title="Home"
        description="Strong Foundations works with children who have been diagnosed with autism, Asperger Syndrome, PDD-NOS, social communication disorder & others living in Vernon, Tolland County."
      />
      <Hero
        image={banner}
        header="Welcome to Strong Foundations, ABA programs for children of all abilities"
      />
      <section className="container my-6 mx-auto pt-0 sm:px-24 px-4 pb-6">
        <div className="grid gap-4 sm:grid-cols-12 items-center">
          <div className="sm:col-span-12 md:col-span-7 space-y-4 font-serif text-lg sm:text-xl text-gray-500">
            <p>
              Strong Foundations offers center and community-based ABA therapy,
              alongside occupational therapy, speech language, and counseling
              services (CBT).
            </p>
            <p>
              Our services and programs are thoughtfully designed for children
              aged 3 to 11 who have been diagnosed with Autism Spectrum
              Disorders, social communication disorders, and related
              disabilities. Additionally, we are dedicated to supporting
              children with mild to moderate language and cognitive delays, as
              well as addressing their behavioral and social-emotional needs.
            </p>
          </div>
          <div className="my-6 sm:col-span-12 md:col-span-5 text-center">
            <SideCTA buttonText="Take the next step" destination="/contact-us/">
              <p className="text-xl text-gray-500">
                Review the{" "}
                <Link to="/services/" title="Strong Foundations' Services">
                  services
                </Link>{" "}
                we offer, ask any questions, and contact us{" "}
                <Link to="/contact-us/" title="Contact Strong Foundations">
                  here
                </Link>
                .
              </p>
            </SideCTA>
          </div>
        </div>
      </section>
      <section className="container my-4 mx-auto pt-0 sm:px-24 px-4 pb-6">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
          {data.contentfulCardCollection.cardCollection.references.map(card => (
            <Card
              key={card.id}
              h2={card.heading}
              image={card.image.gatsbyImageData}
              alt={card.imageAltText}
              buttonText={card.buttonText}
              destination={card.destination}
              textAlignment="center"
            >
              {renderRichText(card.cardContent, options)}
            </Card>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
